const commonClasses = document.querySelectorAll( '.e-stn--on-load-line-animation' );
const aboutCards = document.querySelectorAll( '.e-stn--about-cards' );

function addShowClass( element ) {
  if ( element ) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const callback = ( entries ) => {
      entries.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          element.classList.add( 'show-svg' );
        }
      } );
    };

    const observer = new IntersectionObserver( callback, options );
    observer.observe( element );
  }
}

// Check if the NodeLists contain elements before iterating
if ( commonClasses.length > 0 ) {
  commonClasses.forEach( ( element ) => addShowClass( element ) );
}

if ( aboutCards.length > 0 ) {
  aboutCards.forEach( ( element ) => addShowClass( element ) );
}
