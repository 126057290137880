const staticUpdatesForBrowserResize = () => {
  // Select all .cards-container elements
  const cardsWrapper = document.querySelectorAll( '.e-sld--how-we-help-slider .cards-container' );

  cardsWrapper.forEach( ( wrapper ) => {
    // Find and update '.wrapped-elements' within each .cards-container
    const wrappedElements = wrapper.querySelectorAll( '.wrapped-elements' );
    wrappedElements.forEach( ( element ) => {
      element.classList.remove( 'cards-row-hovered' );
      element.style.transform = 'none';
    } );

    // Find and update '.swiper-slide' within each .cards-container
    const swiperSlides = wrapper.querySelectorAll( '.swiper-slide' );
    swiperSlides.forEach( ( slide ) => {
      slide.classList.remove( 'card-wrapper-hovered' );
    } );

    // Find and update '.e-crd' within each .cards-container
    const eCrds = wrapper.querySelectorAll( '.e-crd' );
    eCrds.forEach( ( card ) => {
      card.classList.remove( 'card-hovered' );
    } );
  } );
};

export { staticUpdatesForBrowserResize };
