/* eslint-disable */
document.addEventListener( 'DOMContentLoaded', function () {
  const leadershipCardsSection = document.querySelectorAll( '.e-stn--leader-cards' );
  if ( leadershipCardsSection.length === 0 ) return;
  const cards = document.querySelectorAll( '.e-stn--leader-cards .e-sgp--dlt > a' );
  cards.forEach( ( card, index ) => {
    // Calculate class number (1 to 4) based on the index
    const classNumber = ( index % 4 ) + 1;
    card.classList.add( `leader-card-${classNumber}` );
  } );
} );