/* eslint-disable max-len */
document.addEventListener( 'DOMContentLoaded', () => {
  const privacyContent = document.querySelector( '.e-stn--privacy-policy' );
  if ( !privacyContent ) return;
  const header = document.querySelector( 'header' );
  const headerHeight = header ? header.offsetHeight : 0;
    
  const handleAnchorClick = ( event ) => {
    const targetId = event.target.getAttribute( 'href' );
    if ( targetId.startsWith( '#' ) ) {
      event.preventDefault();
    
      const targetElement = document.querySelector( targetId );
      if ( targetElement ) {
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
        const adjustedPosition = targetPosition - ( headerHeight + 20 );
          
        window.scrollTo( {
          top: adjustedPosition,
          behavior: 'smooth',
        } );
      }
    }
  };
  const anchorLinks = document.querySelectorAll( 'a[href^="#"]' );
  anchorLinks.forEach( ( link ) => {
    link.addEventListener( 'click', handleAnchorClick );
  } );
} );