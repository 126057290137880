import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

document.addEventListener( 'DOMContentLoaded', () => {
  const stickyCardsSection = document.querySelector( '.e-stn--client-success-posts' );
  const stickyCards = document.querySelectorAll( '.e-stn--client-success-posts .e-tbs__content .swiper-slide' );
  const stickyTabs = document.querySelectorAll( '.e-stn--client-success-posts .e-tbs__nav .swiper-slide' );
  let isClicked = false;

  if ( window.innerWidth < 992 || stickyCards.length === 0 ) {
    return;
  }

  const stickyCardsSectionHeight = stickyCardsSection.offsetHeight;
  stickyCardsSection.setAttribute( 'data-height', stickyCardsSectionHeight );

  const stickyCardsGsap = gsap.utils.toArray( '.e-stn--client-success-posts .e-tbs__content .swiper-slide' );

  stickyCardsGsap.forEach( ( stickyCard, index ) => {
    stickyTabs[0].classList.add( 'active' );
    const cardHeight = stickyCard.offsetHeight;

    function activateStickyCard( isClicked ) {
      stickyCards.forEach( card => card.classList.remove( 'active' ) );
      stickyTabs.forEach( tab => tab.classList.remove( 'active' ) );
      if ( !isClicked ) {
        stickyCard.classList.add( 'active' );
        stickyTabs[index].classList.add( 'active' );
      }
    }

    gsap.to( stickyCard, {
      transformOrigin: 'top center',
      duration: 1,
      scrollTrigger: {
        trigger: stickyCard,
        start: 'top 400px',
        end: `+=${cardHeight}`,
        scrub: 0.8,
        onEnter: () => {
          activateStickyCard( isClicked );
        },
        onEnterBack: () => {
          activateStickyCard( isClicked );
        },
      },
    } );
  } );

  stickyTabs.forEach( ( tab, i ) => {
    tab.addEventListener( 'click', () => {
      isClicked = true;
      const clickedCard = stickyCards[i];
      const headerHeight = document.querySelector( 'header' ).offsetHeight + 120;
      window.scrollTo( {
        top: clickedCard.getBoundingClientRect().top + window.scrollY - headerHeight,
        behavior: 'smooth'
      } );

      setTimeout( () => {
        stickyCards.forEach( card => card.classList.remove( 'active' ) );
        stickyTabs.forEach( tab => tab.classList.remove( 'active' ) );
        stickyCards[i].classList.add( 'active' );
        stickyTabs[i].classList.add( 'active' );
        isClicked = false;
      }, 300 );
    } );
  } );
} );
