import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );
	
const scrollCards = document.querySelectorAll( '.e-div--js-scroll-card' );

const calcHeights = () => {
  // Calculate the max height among sticky cards
  const maxHeight = Math.max( ...Array.from( scrollCards ).map( card => card.getBoundingClientRect().height ) );
  
  // Apply the max height to all sticky cards
  scrollCards.forEach( card => {
    card.style.height = `${maxHeight}px`;
  } );

};

if ( scrollCards.length > 0 ) {
  calcHeights();
  const scrollCardsGsap = gsap.utils.toArray( '.e-div--js-scroll-card:not(:last-child)' );
  scrollCardsGsap.forEach( ( storyCard, index ) => {

    const startPosition = index * 30 + 65;
    const scaleValue = 0.8 + ( index * 0.2 / ( scrollCards.length - 1 ) );	
    gsap.to( storyCard, {
      scale: scaleValue,
      translateY: 0,
      opacity: 1,
      transformOrigin: 'top center',
      duration: 1,
      scrollTrigger: {
        trigger: storyCard,
        start: `top ${startPosition}`,
        end: 'bottom top',
        scrub: 0.8
      },
    } );

    // Create a new element for the fade-in effect
    const fadeInElement = document.createElement( 'div' );
    fadeInElement.classList.add( 'fade-in-element' );
    storyCard.appendChild( fadeInElement );

    gsap.to( fadeInElement, {
      opacity: 0.5,
      duration: 1,
      scrollTrigger: {
        trigger: storyCard,
        start: `top ${startPosition}`,
        end: 'bottom top',
        scrub: 0.8,
      },
    } );

    const lastStoryCard = scrollCards[scrollCards.length - 1];
    gsap.to( lastStoryCard, {
      translateY: 0,
      duration: 1,
      scrollTrigger: {
        trigger: lastStoryCard,
        start: 'top center',
        end: 'bottom top',
        scrub: 0.8,
      },
    } );
  } );
}