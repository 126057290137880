document.addEventListener( 'DOMContentLoaded', () => {
  const stickyBanner = document.querySelector( '.e-stn--sticky-banner' );
  const nextSection = stickyBanner?.nextElementSibling;
  if ( stickyBanner && nextSection ) {
    const checkNextSectionPosition = () => {
      const nextSectionRect = nextSection.getBoundingClientRect();
      const isNextSectionAtTop = nextSectionRect.top <= 0;

      if ( isNextSectionAtTop ) {
        stickyBanner.classList.add( 'banner-relative' );
      } else {
        stickyBanner.classList.remove( 'banner-relative' );
      }
    };

    window.addEventListener( 'scroll', checkNextSectionPosition );

    checkNextSectionPosition();
  }
} );
