const card = document.querySelector( '.e-stn--home-challenging-animation-stack' );
const mediaQuery = window.matchMedia( '(min-width: 768px)' );
const bsSection = document.querySelector( '.e-stn--home-challenging-animation-stack' );
const bsColumnContent = bsSection ? bsSection.querySelector( '.e-col--content' ) : null;
const bsColumnRight = bsSection ? bsSection.querySelector( '.e-col--right' ) : null;

const setInitialOpacity = () => {
  if ( card ) {
    setTimeout( () => {
      const innerContainer = card.querySelector( '.wp-block-group > .e-div' );
      innerContainer.style.opacity = '1';
    }, 1000 );
  }
};

const getOpacities = ( SVGDocument ) => {
  return ['#vaco-scroll-alt-u-top', '#vaco-scroll-alt-u-center', '#vaco-scroll-alt-u-bottom'].map( id => {
    const transformStr = SVGDocument.querySelector( id )?.getAttribute( 'transform' );
    return transformStr ? parseFloat( transformStr.split( '(' )[1].split( ')' )[0].split( ' ' )[0] ) : 0;
  } );
};

const updateElementVisibility = ( opacities ) => {
  const visibilityConditions = [
    opacities[0] >= -100 && opacities[1] > -31 && opacities[2] >= 6,
    opacities[0] > -23 && opacities[1] <= -22 && opacities[2] >= -28,
    opacities[0] >= 6 && opacities[1] >= -23 && opacities[2] <= -22,
  ];

  ['.e-div--plate-one', '.e-div--plate-two', '.e-div--plate-three'].forEach( ( target, index ) => {
    const element = document.querySelector( target )?.querySelector( '.e-div' );
    if ( element ) {
      element.style.display = visibilityConditions[index] ? 'block' : 'none';
    }
  } );
};

const handleScroll = ( SVGDocument, player ) => {
  const scrollPercentage = getScrollPercentage();
  player?.seek( scrollPercentage.toFixed( 2 ) );
  updateElementVisibility( getOpacities( SVGDocument ) );
};

const getScrollPercentage = () => {
  const sectionTop = card.getBoundingClientRect().top + window.scrollY;
  const sectionHeight = card.offsetHeight;
  const scrollTop = window.scrollY;
  const windowHeight = window.innerHeight;
  const visibleHeight = Math.min( sectionHeight, scrollTop + windowHeight - ( sectionTop + 388 ) );
  return Math.min( 99, Math.max( 0, ( visibleHeight / sectionHeight ) * 100 ) );
};

const loadSVGAnimation = () => {
  if ( !card ) return;
  const objectTag = card.querySelector( '.e-svg object' );
  const loadHandler = () => {
    const SVGDocument = objectTag.contentDocument || objectTag.contentWindow.document;
    const player = SVGDocument.querySelector( 'svg' )?.svgatorPlayer || null;
    if ( player ) {
      window.addEventListener( 'scroll', () => handleScroll( SVGDocument, player ) );
    }
    objectTag.removeEventListener( 'load', loadHandler );
  };

  if ( objectTag.contentDocument ) loadHandler();
  else objectTag.addEventListener( 'load', loadHandler );
};

const moveBlocks = () => {
  if ( !bsColumnContent || !bsColumnRight ) return;
  const htmlBlockContent = bsColumnContent.querySelector( '.wp-block-group' );
  const htmlBlockImage = bsColumnRight.querySelector( '.wp-block-group' );

  if ( mediaQuery.matches && !htmlBlockContent && htmlBlockImage ) {
    bsColumnContent.appendChild( htmlBlockImage );
  } else if ( !mediaQuery.matches && htmlBlockContent ) {
    bsColumnRight.appendChild( htmlBlockContent );
  }
};

const init = () => {
  moveBlocks();
  mediaQuery.addEventListener( 'change', moveBlocks );

  bsColumnContent?.querySelectorAll( 'p' ).forEach( p => {
    p.innerHTML = `<span>${p.innerHTML}</span>`;
  } );

  loadSVGAnimation();
  setInitialOpacity();
};

window.addEventListener( 'load', init );


