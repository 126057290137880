/* eslint-disable max-len */
class TableContent {
  constructor( section ) {
    this.selector = document.querySelector( section );
    this.tableOfContent = this.selector?.querySelector( '.e-div--left-toc, .e-div--table-of-content' );
    if ( !this.tableOfContent ) return;
    this.links = this.tableOfContent?.querySelectorAll( 'p a, ul a' );
    this.contents = this.selector.querySelectorAll( '.wp-block-heading' );
    this.header = document.querySelector( 'header' );
    this.headerHeight = this.header.offsetHeight;
    this.contentPosition = [];
    this.createProgressBar();
    this.getContentPosition();
    this.bindTableContentClick();
    this.setTableContentSticky();
    this.handleScroll = this.handleScroll.bind( this );
    window.addEventListener( 'scroll', this.handleScroll );
    // Set the active item on page load
    if ( window.screenY > 0 ) {
      this.handleScroll();
    } else {
      this.setActiveTableContent();
    }
  }
  
  
  handleScroll() {
    this.updateProgressBar();
    this.setActiveTableContent();
  }
  
  bindTableContentClick() {
    this.links?.forEach( link => {
      link.addEventListener( 'click', ( event ) => {
        const href = link.getAttribute( 'href' ).substring( 1 );
        event.preventDefault();
        // Recalculate positions before scrolling to ensure accuracy
        this.getContentPosition();
        this.scrollToSection( href );
      } );
    } );
  }

  getContentPosition() {
    const isLargeScreen = window.matchMedia( '(min-width: 992px)' ).matches;
    
    // Declare the offset variable outside the blocks
    let offset;

    // Determine the offset based on the type of table of content
    if ( this.tableOfContent?.classList.contains( 'e-div--left-toc' ) ) {
      offset = isLargeScreen ? this.headerHeight + 50 : this.headerHeight + 55; // Offset for `.e-div--left-toc`
    } else if ( this.tableOfContent?.classList.contains( 'e-div--table-of-content' ) ) {
      offset = isLargeScreen ? this.headerHeight + 40 : this.headerHeight + 45; // Offset for `.e-div--table-of-content`
    } else {
      offset = isLargeScreen ? this.headerHeight + 50 : this.headerHeight + 55; // Default offset
    }
  
    // Clear previous content positions
    this.contentPosition = [];
  
    this.contents.forEach( ( content ) => {
      const id = content.getAttribute( 'id' );
      if ( id === null ) return;
  
      // Calculate the position of the content relative to the viewport and header
      const position = content.getBoundingClientRect().top + window.scrollY - offset;
  
      this.contentPosition.push( { id, position } );
    } );
  }
  
  
  scrollToSection( href ) {
    this.contentPosition.forEach( array => {
      if ( array.id !== href ) return;
      this.windowScroller( array.position );
    } );
  }
  
  createProgressBar() {
    const div = document.createElement( 'div' );
    div.classList.add( 'progress-bar' );
    this.header.append( div );
  }

  setActiveTableContent() {
    const scrollTop = window.scrollY + this.header.offsetHeight + 20;
    let activeId = '';
    for ( const item of this.contentPosition ) {
      if ( scrollTop >= item.position ) {
        activeId = item.id;
      }
    }
  
    this.links.forEach( link => {
      const href = link.getAttribute( 'href' ).substring( 1 );
      if ( href === activeId ) {
        link.classList.add( 'active' );
      } else {
        link.classList.remove( 'active' );
      }
    } );
    // If no activeId found, make the first link active
    if ( !activeId && this.links.length > 0 ) {
      this.links[0].classList.add( 'active' );
    }
  }
  
  updateProgressBar() {
    const scrollPos = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = this.selector.offsetHeight + this.selector.nextElementSibling.offsetHeight;
    const progress = ( scrollPos / ( documentHeight - windowHeight ) ) * 100;
    const progressBar = document.querySelector( '.progress-bar' );
    document.documentElement.style.setProperty( '--scroll-progress', progress + '%' );
    if ( progress > 0 ) {
      progressBar.classList.add( 'progress-bar-active' );
    } else {
      progressBar.classList.remove( 'progress-bar-active' );
    }
  }

  setTableContentSticky() {
    const tableContentHeight = this.tableOfContent?.getBoundingClientRect().height;
    const windowHeight = window.innerHeight - ( this.headerHeight + 100 );
    if( tableContentHeight > windowHeight ) {
      this.tableOfContent.classList.add( 'remove-sticky' );
    }
  }
  
  windowScroller( scrollPosition ) {
    window.scrollTo( {
      top: scrollPosition,
      behavior: 'smooth'
    } );
  } 
}

const tableContent = new TableContent( '.e-stn--privacy-policy, .e-stn--blog-inner-content' );
document.addEventListener( 'DOMContentLoaded', tableContent );
