import { handleMouseOver } from '../how-we-help-animations/how-we-help-sub-mouseover';
import { handleMouseOut } from '../how-we-help-animations/how-we-help-sub-mouseout';

const mediaQueryOne = window.matchMedia( '(min-width: 992px)' );

const setupCardHoverHandling = ( animatingCards, cardsWrapperWidth, windowWidthForCalculate, isCardOpen ) => {
  if ( mediaQueryOne.matches ) {
    animatingCards.forEach( ( card ) => {
      card.addEventListener( 'mouseleave', ( e ) => {
        // Ensure that isCardOpen is set to true when leaving
        isCardOpen = true;
        handleMouseOut( e );
        setTimeout( () => {
          // Reset isCardOpen flag after animation duration
          isCardOpen = false;
        }, 570 );
      } );

      card.addEventListener( 'mouseenter', ( e ) => {
        // Delay and prevent mouseover handling if the card is "open"
        if ( !isCardOpen ) {
          setTimeout( () => {
            handleMouseOver( e, cardsWrapperWidth, windowWidthForCalculate );
            isCardOpen = true; // Prevent re-triggering
          }, 50 );
        } else {
          // This allows mouseover to happen after 570ms even if the card is open
          setTimeout( () => {
            handleMouseOver( e, cardsWrapperWidth, windowWidthForCalculate );
            isCardOpen = true; // Make sure the flag remains true
          }, 570 );
        }
      } );
    } );
  }
};

export { setupCardHoverHandling };
