import { cardsStructureRemove } from '../custom/how-we-help-animations/how-we-help-sub-card-structure-remove';
import { organizingCards } from '../custom/how-we-help-animations/how-we-help-organizing-cards';
// eslint-disable-next-line max-len
import { staticUpdatesForBrowserResize } from '../custom/how-we-help-animations/how-we-help-static-updates-for-browser-resize';
import { setupCardRowHandling } from '../custom/how-we-help-animations/how-we-help-cards-row-mouse-leave';
import { setupCardHoverHandling } from '../custom/how-we-help-animations/how-we-help-card-hover-handle';


const slideWrappers = document.querySelectorAll( '.e-sld--how-we-help-slider .swiper-slide' ),
  cards = document.querySelectorAll( '.e-sld--how-we-help-slider .swiper-slide' ),
  mediaQueryOne = window.matchMedia( '(min-width: 992px)' ),
  windowWidth = window.innerWidth;

let isCardOpen = false,
  previousWidth = window.innerWidth,
  isCardsOrganized = false;

if ( slideWrappers.length ) {

  const organizeCards = () => {
    organizingCards( slideWrappers );
    isCardsOrganized = true;
  };

  const mountOrganizeCards = () => {
    if ( !isCardsOrganized && mediaQueryOne.matches ) {
      organizeCards();
    }
  };

  mountOrganizeCards();

  const cardsWrapper = document.querySelectorAll( '.e-sld--how-we-help-slider .cards-container' );
  const cardsWrapperWidth = cardsWrapper[0] ? cardsWrapper[0].offsetWidth : 0;
  const cardsRow = document.querySelectorAll( '.e-sld--how-we-help-slider .wrapped-elements' );
  setupCardHoverHandling( cards, cardsWrapperWidth, windowWidth, isCardOpen );
  setupCardRowHandling( cardsRow );

  const handleBrowserWidthChange = () => {
    const currentWidth = window.innerWidth;
    if ( currentWidth !== previousWidth ) {
      staticUpdatesForBrowserResize();
      mountOrganizeCards();

      if ( !mediaQueryOne.matches ) {
        cardsStructureRemove();
        isCardsOrganized = false;
      }

      const changedCardsWrapper = document.querySelectorAll( '.e-sld--how-we-help-slider .cards-container' );
      const changedCardsWrapperWidth = changedCardsWrapper[0] ? changedCardsWrapper[0].offsetWidth : 0;
      const windowChangedWidth = window.innerWidth;
      const changedCardsRow = document.querySelectorAll( '.e-sld--how-we-help-slider .wrapped-elements' );
      const changedCards = document.querySelectorAll( '.e-sld--how-we-help-slider .swiper-slide' );
      setupCardHoverHandling( changedCards, changedCardsWrapperWidth, windowChangedWidth, isCardOpen );
      setupCardRowHandling( changedCardsRow );
      previousWidth = currentWidth;
    }
  };

  const debounce = ( func, delay ) => {
    let timeout;
    return function() {
      clearTimeout( timeout );
      timeout = setTimeout( func, delay );
    };
  };

  window.addEventListener( 'resize', debounce( handleBrowserWidthChange, 500 ) );
}


