const organizingCards = ( slideWrappers ) => {
  // Convert the slideWrappers NodeList to an array
  const slidesArray = Array.from( slideWrappers );

  // Iterate over each slide wrapper
  slidesArray.forEach( slide => {
    // Remove the style attribute
    slide.removeAttribute( 'style' );

    // Remove all existing classes
    slide.classList.remove( ...slide.classList );

    // Add the 'swiper-slide' class
    slide.classList.add( 'swiper-slide' );

    // Find .e-crd elements inside this slide and remove the 'card-hovered' class
    const eCards = slide.querySelectorAll( '.e-crd' );
    eCards.forEach( card => {
      card.classList.remove( 'card-hovered' );
    } );
  } );

  // Create a wrapper for every group of 4 slides
  for ( let i = 0; i < slidesArray.length; i += 4 ) {
    const wrappedElements = document.createElement( 'div' );
    wrappedElements.classList.add( 'wrapped-elements' );

    // Append the slides to the wrapped-elements div
    slidesArray.slice( i, i + 4 ).forEach( slide => {
      wrappedElements.appendChild( slide );
    } );

    // Find the parent container and append the wrapped-elements div
    const cardsContainer = document.querySelector( '.e-sld--how-we-help-slider' );
    cardsContainer.appendChild( wrappedElements );
  }

  // Create the cards-container div and wrap the wrapped-elements divs inside it
  const wrappedElements = document.querySelectorAll( '.wrapped-elements' );
  const cardsContainerDiv = document.createElement( 'div' );
  cardsContainerDiv.classList.add( 'cards-container' );

  wrappedElements.forEach( wrappedElement => {
    cardsContainerDiv.appendChild( wrappedElement );
  } );

  // Append the cards-container to the parent container
  const slider = document.querySelector( '.e-sld--how-we-help-slider .swiper' );
  slider.appendChild( cardsContainerDiv );

  // Add the respective classes to every 4th, 3rd, and 2nd slides
  const slides = document.querySelectorAll( '.e-sld--how-we-help-slider .swiper-slide' );
  
  slides.forEach( ( slide, index ) => {
    if ( ( index + 1 ) % 4 === 0 ) {
      slide.classList.add( 'card--right-side-first' );
    } else if ( ( index + 1 ) % 4 === 3 ) {
      slide.classList.add( 'card--right-side-second' );
    } else if ( ( index + 1 ) % 4 === 2 || ( index + 1 ) % 4 === 3 ) {
      slide.classList.add( 'card--center-side' );
    }
  } );

  // Add the 'structured' class to the parent slider
  const sliderElement = document.querySelector( '.e-sld--how-we-help-slider' );
  sliderElement.classList.add( 'structured' );
};

export { organizingCards };
