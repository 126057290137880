
const toggleActiveClass = () => {
  // Handle events on input elements
  const inputs = document.querySelectorAll( '.e-stn--contact-form .hs-input, .e-stn--forms-section .hs-input' );
    
  inputs.forEach( input => {
    input.addEventListener( 'load', handleInputChange );
    input.addEventListener( 'change', handleInputChange );
    input.addEventListener( 'input', handleInputChange );
    input.addEventListener( 'focus', handleInputChange );
    input.addEventListener( 'focusout', handleFocusOut );
  } );

  function handleInputChange( e ) {
    const parent = e.currentTarget.closest( '.hs-input' ).parentElement.parentElement;
    if ( !e.currentTarget.value || e.currentTarget.value !== '' ) {
      parent.classList.add( 'active' );
    } else {
      parent.classList.remove( 'active' );
    }
  }

  function handleFocusOut( e ) {
    const parent = e.currentTarget.closest( '.hs-input' ).parentElement.parentElement;
    if ( !e.currentTarget.value ) {
      parent.classList.remove( 'active' );
    } else {
      parent.classList.add( 'active' );
    }
  }
};

function selectEmptyOption() {
  const selectElements = document.querySelectorAll( 'select' );
  selectElements.forEach( select => {
    const firstOption = select.querySelector( 'option' );
    if ( firstOption ) {
      firstOption.innerHTML = '';
    }
  } );
}


const setupFormAction = ( event ) => {
  if ( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
    const inputs = document.querySelectorAll(
      /* eslint-disable max-len */
      'input[type="text"].hs-input, input[type="password"].hs-input, input[type="email"].hs-input, input[type="tel"].hs-input, textarea.hs-input'
      /* eslint-enable max-len */
    );
    selectEmptyOption();
    const initialFieldValues = new Map();

    inputs.forEach( input => {
      const fieldName = input.getAttribute( 'name' );
      initialFieldValues.set( fieldName, '' );
    } );

    setInterval( () => {
      inputs.forEach( input => {
        const fieldName = input.getAttribute( 'name' );
        const currentValue = input.value;

        if ( currentValue !== initialFieldValues.get( fieldName ) ) {
          input.closest( '.hs-input' ).parentElement.parentElement.classList.add( 'active' );
          initialFieldValues.set( fieldName, currentValue );
        }
      } );
    }, 300 );

    toggleActiveClass();

    const hsSubmit = document.querySelector( '.bs-div--form-wrapper .hs-submit' );
    if ( hsSubmit ) {
      const submitInput = hsSubmit.querySelector( 'input[type="submit"]' );
      hsSubmit.addEventListener( 'click', () => {
        submitInput.click();
      } );
    }
  }
};

window.addEventListener( 'message', event => {
  try {
    const allowedOrigins = ['https://forms.hsforms.com', window.location.origin];
    if ( allowedOrigins.includes( event.origin ) ) {
      setupFormAction( event );
    } else {
      return false;
    }
  } catch ( e ) {
    console.error( e );
  }
} );

