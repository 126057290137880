import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );
	
if ( window.innerWidth > 768 ) {
  const storyCards = document.querySelectorAll( '.e-div--history-card' );
  if ( storyCards.length > 0 ) {
			
    const storyCardsGsap = gsap.utils.toArray( '.e-div--history-card:not(:last-child)' );
    storyCardsGsap.forEach( ( storyCard, index ) => {
      const startPosition = index * 30 + 140;
      const scaleValue = 0.8 + ( index * 0.2 / ( storyCards.length - 1 ) );
				
      gsap.to( storyCard, {
        scale: scaleValue,
        translateY: -80,
        opacity: 0.8,
        transformOrigin: 'top center',
        duration: 10,
        scrollTrigger: {
          trigger: storyCard,
          start: `top ${startPosition}`,
          end: 'bottom top',
          scrub: 2
        },
      } );

      // Create a new element for the fade-in effect
      const fadeInElement = document.createElement( 'div' );
      fadeInElement.classList.add( 'fade-in-element' );
      storyCard.appendChild( fadeInElement );

      gsap.to( fadeInElement, {
        opacity: 0.8,
        duration: 1,
        scrollTrigger: {
          trigger: storyCard,
          start: `top ${startPosition}`,
          end: 'bottom top',
          scrub: 2,
        },
      } );

      const lastStoryCard = storyCards[storyCards.length - 1];
      gsap.to( lastStoryCard, {
        translateY: -80,
        duration: 1,
        scrollTrigger: {
          trigger: lastStoryCard,
          start: 'top center',
          end: 'top top',
          scrub: 2,
        },
      } );
					
    } );
  }
}