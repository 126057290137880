function checkScroll() {
  document.body.classList.toggle( 'scrolled', window.scrollY > 1 );
}
  
function setMainMarginTop() {
  const header = document.querySelector( '.transparent-header header > .e-div, .error404 header > .e-div' );
  const main = document.querySelector( 'main, .entry-content, .e-stn--404-section' );
  if ( header && main ) {
    main.style.setProperty( 'margin-top', -header.offsetHeight + 'px', 'important' );
  }
}

function addMenuHoveredClass() {
  document.querySelector( 'body' ).classList.add( 'menu-hover' );
  document.querySelector( '.e-div--header-wrapper .max-mega-menu' )?.classList.add( 'mega-menu-hover' );
}
  
function removeMenuHoveredClass() {
  document.querySelector( 'body' ).classList.remove( 'menu-hover' );
  document.querySelector( '.e-div--header-wrapper .max-mega-menu' )?.classList.remove( 'mega-menu-hover' );
}

function addDropdownHoveredClass() {
  document.querySelector( 'body' ).classList.add( 'menu-has-children-hover' );
}

function removeDropdownHoveredClass() {
  document.querySelector( 'body' ).classList.remove( 'menu-has-children-hover' );
}

function mobileBottomStickyMenu() {
  const menu = document.querySelector( '#mega-menu-primary_menu' );
  const button = menu.querySelector( '.orange-btn' );
  const search = menu.querySelector( '.menu-search-icon' );
  const languageMenu = menu.querySelector( '.mega-wpml-ls-item' );
  const newUlContainer = document.createElement( 'ul' );
  const newLiContainer = document.createElement( 'li' );

  newUlContainer.classList.add( 'sticky-menu-container' );
  newLiContainer.appendChild( newUlContainer );

  if ( window.innerWidth < 1200 ) {
    if ( !document.querySelector( '#mega-menu-primary_menu > li > .sticky-menu-container' ) ) {
      newUlContainer.appendChild( search );
      newUlContainer.appendChild( button );
      newUlContainer.appendChild( languageMenu );
      menu.appendChild( newLiContainer );
    }
  } else {
    const existingUlContainer = document.querySelector( '#mega-menu-primary_menu > li > .sticky-menu-container' );
    if ( existingUlContainer ) {
      menu.appendChild( search );
      menu.appendChild( languageMenu );
      menu.appendChild( button );
      existingUlContainer.parentElement.remove(); 
    }
  }
}

function updateMenuItems() {
  document.querySelectorAll( '#mega-menu-primary_menu > .mega-menu-item-has-children' ).forEach( item => {
    item.classList.remove( 'mega-toggle-on' );
    const subMenu = item.querySelector( '.mega-sub-menu' );
    if ( subMenu && subMenu.parentNode === item ) subMenu.style.display = 'none';
    const anchorTag = item.querySelector( 'a' );
    if ( anchorTag && anchorTag.parentNode === item ) anchorTag.setAttribute( 'aria-expanded', 'false' );
  } );
}

function toggleHeaderClassOnSubMenuToggle() {
  const header = document.querySelector( 'header' );
  const anySubMenuExpanded = document.querySelector( '.mega-menu-item.mega-toggle-on' );

  if ( anySubMenuExpanded ) {
    header.classList.add( 'sub-menu-enabled' );
  } else {
    header.classList.remove( 'sub-menu-enabled' );
  }
}

document.addEventListener( 'DOMContentLoaded', function () {
  const header = document.querySelector( 'header' );
  const menuItems = document.querySelectorAll( '.e-div--header-wrapper .max-mega-menu > li.mega-menu-item:not(.orange-btn, .menu-search-icon, .mega-wpml-ls-menu-item)' ); /* eslint-disable-line max-len */
  const menuItemHasChildren = document.querySelectorAll( '.e-div--header-wrapper .max-mega-menu > li.mega-menu-item-has-children:not(.orange-btn, .menu-search-icon, .mega-wpml-ls-menu-item)' ); /* eslint-disable-line max-len */
  const languageSwitcher = document.querySelector( '.e-div--header-wrapper .mega-wpml-ls-current-language a' );
  const menu = document.querySelector( '.e-div--header-wrapper .max-mega-menu' );
  const menuWrapper = document.querySelector( '.e-div--header-wrapper > .e-div' );
  const menuToggle = document.querySelector( '#mega-menu-wrap-primary_menu .mega-menu-toggle' );

  
  if ( languageSwitcher ) {
    languageSwitcher.setAttribute( 'href', '' );
  }
  menuItems.forEach( item => {
    item.addEventListener( 'mouseenter', addMenuHoveredClass );
    menu.addEventListener( 'mouseleave', removeMenuHoveredClass );
  } );
  
  menuItemHasChildren.forEach( item => {
    item.addEventListener( 'mouseenter', addDropdownHoveredClass );
    item.addEventListener( 'mouseleave', removeDropdownHoveredClass );
  } );

  menuToggle.addEventListener( 'click', function() {
    header.classList.remove( 'sub-menu-enabled' );
    updateMenuItems();
  } );

  menuItemHasChildren.forEach( menu => {
    menu.addEventListener( 'click', function() {
      setTimeout( toggleHeaderClassOnSubMenuToggle, 100 );
    } );
  } );

  if ( menuWrapper ) {
    const newSpan = document.createElement( 'span' );
    newSpan.textContent = 'Back';
    menuWrapper.appendChild( newSpan );
    newSpan.addEventListener( 'click', function() {
      header.classList.remove( 'sub-menu-enabled' );
      updateMenuItems();
    } );
  }

  checkScroll();
  setMainMarginTop();
  mobileBottomStickyMenu();
} );
  
window.onscroll = checkScroll;
  
window.onresize = function() {
  checkScroll();
  setMainMarginTop();
  mobileBottomStickyMenu();
};