document.addEventListener( 'DOMContentLoaded' , function() { 
  ( () => {
    const
      prImages = document.querySelectorAll( '.e-col--parallax-images .e-img' ),
      parallaxSection = document.querySelector( '.e-stn--careers-benefits' ),
      speedValues = [0.06, -0.1, -0.08];

    if ( !parallaxSection || parallaxSection.length === 0 ) {
      return false;
    }
    
    const observer = new IntersectionObserver( ( entries ) => {
      entries.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          window.addEventListener( 'scroll', parallaxScroll );
        } else {
          window.removeEventListener( 'scroll', parallaxScroll );
        }
      } );
    } );

    observer.observe( parallaxSection );

    function parallaxScroll() {
      const scrollY = -parallaxSection.getBoundingClientRect().top + 2700;

      prImages.forEach( ( image, index ) => {
        const yOffset = ( scrollY - index * 10 ) * speedValues[index];
        image.style.transform = `translate3d(0, ${yOffset}px, 0)`;
        image.classList.add( 'active' );
      } );
    }
    parallaxScroll();
  } ) ();
} );
