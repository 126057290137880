document.addEventListener( 'DOMContentLoaded', function () {
  const targetElements = document.querySelectorAll( '.e-stn--observe' ); // Use querySelectorAll

  if ( targetElements.length > 0 ) {
    const observer = new IntersectionObserver( ( entries, observer ) => {
      entries.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          entry.target.classList.add( 'animate-section' );
          observer.unobserve( entry.target );
        }
      } );
    }, {
      rootMargin: '0px 0px -100px 0px',
    } );

    targetElements.forEach( ( element ) => observer.observe( element ) ); // Iterate over the NodeList
  }
} );
