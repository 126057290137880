import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

( () => {
  
  let groupLeft = document.querySelector( '.e-stn--careers-our-core-values .e-div--right' ),
    boxesNotInGroup = gsap.utils.toArray( '.e-div--right' ).filter( el => el.parentNode !== groupLeft );
  
  let groupRight = document.querySelector( '.e-stn--careers-our-core-values .e-div--left' ),
    boxesNotInGroupLeft = gsap.utils.toArray( '.e-div--left' ).filter( el => el.parentNode !== groupRight );
  
  if ( groupLeft ) {
    boxesNotInGroupLeft.forEach( ( box, index ) => {
      gsap.from( box, {
        opacity: 0,
        x: -100,
        duration: 2,
        stagger: 0.5,
        delay: index * 0.4,
        scrollTrigger: {
          trigger: box,
          start: 'top 80%',
        }
      } );
    } );

    boxesNotInGroup.forEach( ( box, index ) => {
      gsap.from( box, {
        opacity: 0,
        x: 100,
        stagger: 0.5,
        delay: index * 0.4,
        duration: 2,
        scrollTrigger: {
          trigger: box,
          start: 'top 80%',
        }
      } );
    } );
  }

} ) ();
