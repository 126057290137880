document.addEventListener( 'DOMContentLoaded', () => {
  function wrapContentElements() {
    // Select all .swiper-slide elements
    const swiperSlides = document.querySelectorAll( '.e-stn--home-how-we-help-clients .swiper-slide' );

    swiperSlides.forEach( ( slide ) => {
      // Select the elements to be wrapped within the current slide
      const firstParagraph = slide.querySelector( 'p:nth-of-type(1)' );
      const heading = slide.querySelector( 'h3' );
      const secondParagraph = slide.querySelector( 'p:nth-of-type(2)' );

      // Check if all elements are found and aren't already inside a .content-wrapper
      if ( firstParagraph && heading && secondParagraph && !firstParagraph.closest( '.content-wrapper' ) ) {
        // Create a new div with content-wrapper class
        const wrapperDiv = document.createElement( 'div' );
        wrapperDiv.classList.add( 'content-wrapper' );

        // Insert the wrapper before the first paragraph
        firstParagraph.parentNode.insertBefore( wrapperDiv, firstParagraph );

        // Move the selected elements into the new wrapper
        wrapperDiv.appendChild( firstParagraph );
        wrapperDiv.appendChild( heading );
        wrapperDiv.appendChild( secondParagraph );
      }
    } );
  }

  wrapContentElements();
} );
