import gsap from 'gsap';

const headingElement = document.querySelector( '.e-stn--home-challenging-typical-heading h2' );

if ( headingElement ) {
  const textContent = headingElement.textContent;
  const words = textContent.split( ' ' );
  const wrappedText = words.map( word => `<span class="fadeWords">${word}</span>` ).join( ' ' );
  headingElement.innerHTML = wrappedText;

  gsap.utils.toArray( '.fadeWords' ).forEach( element => {
    let animateElement = element;

    gsap.from( animateElement, {
      opacity: 0.3,
      delay: 1,
      duration: 0.5,
      ease: 'sine.out',
      stagger: 0.1,
      scrollTrigger: {
        trigger: element,
        start: 'top 60%', // Adjust the start point as needed
        end: 'bottom 40%',
        scrub: true,
      }
    } );
  } );
}