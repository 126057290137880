import { slideDown, slideUp } from './common';

document.addEventListener( 'DOMContentLoaded', function () {
  const duration = 600;
  const footer = document.querySelector( 'footer' );

  // Find all menu items that have a child (sub-menu)
  const menuItems = footer.querySelectorAll( 'ul.wp-block-navigation > .wp-block-navigation-item.has-child' );
  
  // Add a helper span to each menu item for handling the click
  menuItems.forEach( function ( menuItem ) {
    const span = document.createElement( 'span' );
    span.classList.add( 'footer-drop-down-helper' );
    menuItem.appendChild( span );
  } );

  // Get all the helper spans (for triggering the slide down/up)
  const footerDropDownHelpers = footer.querySelectorAll(
    'ul.wp-block-navigation > .wp-block-navigation-item.has-child span.footer-drop-down-helper'
  );

  footerDropDownHelpers.forEach( function ( helper ) {
    helper.addEventListener( 'click', function ( e ) {
      const li = e.target.parentElement;
      const isExpanded = li.classList.contains( 'footer-expanded' );
      const expandedItems = footer.querySelectorAll( '.footer-expanded' );
      
      // Collapse all other expanded items
      expandedItems.forEach( function ( expandedItem ) {
        const subMenu = expandedItem.querySelector( '.wp-block-navigation__submenu-container' );
        if ( subMenu ) {
          slideUp( subMenu, duration );
          expandedItem.classList.remove( 'footer-expanded' );
        }
      } );

      // Find the sub-menu of the clicked item
      const subMenu = li.querySelector( '.wp-block-navigation__submenu-container' );
      if ( !subMenu ) return; // Exit if no sub-menu exists

      // If the clicked item is already expanded, collapse it
      if ( isExpanded ) {
        slideUp( subMenu, duration );
        li.classList.remove( 'footer-expanded' );
      } else {
        // Otherwise, expand the new item
        li.classList.add( 'footer-expanded' );
        slideDown( subMenu, duration );
      }
    } );
  } );
} );
