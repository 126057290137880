const tabsNav = document.querySelector( '.tabs__nav' );
const tabsNavItems = document.querySelectorAll( '.tabs__nav-item' );
const panels = document.querySelectorAll( '.tabs__panel' );

const initializeTabs = () => {
  const openTabs = ( event ) => {
    const btnTarget = event.currentTarget;
    const { target } = btnTarget.dataset;
    const { textContent } = btnTarget;

    const toggleElement = btnTarget.parentElement?.nextElementSibling?.classList.contains( 'tabs__toggle' )
      ? btnTarget.parentElement.nextElementSibling
      : null;

    if ( toggleElement ) {
      toggleElement.textContent = textContent;
    }

    tabsNavItems.forEach( ( item ) => item.classList.remove( 'active' ) );
    panels.forEach( ( panel ) => panel.classList.remove( 'active' ) );

    document.querySelector( `#${target}` )?.classList.add( 'active' );
    btnTarget.classList.add( 'active' );
  };
  tabsNavItems.forEach( ( tab ) => tab.addEventListener( 'click', openTabs ) );
};

const mobileTabsToggle = () => {
  const mobileToggle = document.querySelector( '.tabs .tabs__toggle' );
  if ( !mobileToggle ) return;
  mobileToggle.addEventListener( 'click', () => {
    tabsNav.classList.toggle( 'active' );
  } );
};

const toggleAccordionItem = ( e ) => {
  let target = e.currentTarget.parentElement;
  if ( !target.classList.contains( 'acdn--open' ) ) {
    target.classList.add( 'acdn--open' );
  } else {
    target.classList.remove( 'acdn--open' );
  }
};

const initializeAccordion = () => {
  panels.forEach( ( panel ) => {
    const accordionItems = panel.querySelectorAll( '.acdn__itm:not(.location-post)' );
    if( accordionItems.length === 0 ) return;
    accordionItems[0].classList.add( 'acdn--open' );
    accordionItems.forEach( ( accordionItem ) => {
      const accordionHeader = accordionItem.querySelector( '.acdn__hdr' );
      accordionHeader.addEventListener( 'click', ( e ) => toggleAccordionItem( e ) );
    } );
  } );
};

document.addEventListener( 'DOMContentLoaded', () => {
  if ( !tabsNav ) return;
  initializeTabs();
  mobileTabsToggle();
  initializeAccordion();
} );
