const cardsStructureRemove = () => {
  // Select all .swiper-slide elements inside .wrapped-elements inside .cards-container
  const slideWrappers = document.querySelectorAll( '.cards-container .wrapped-elements .swiper-slide' );

  // Select the .cards-container element
  const cardsContainer = document.querySelector( '.cards-container' );
  
  if ( cardsContainer && slideWrappers.length > 0 ) {
    // Replace the .cards-container with the selected .swiper-slide elements
    cardsContainer.parentNode.replaceChild( slideWrappers[0], cardsContainer );
    // This replaces the .cards-container with the first .swiper-slide element.
    // You may need a loop or another strategy if you want to replace it with all .swiper-slide elements.
  }
};

export { cardsStructureRemove };
