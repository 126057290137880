document.addEventListener( 'click', function( e ) {
  if ( e.target.classList.contains( 'facetwp-page' ) ) {
    const adminBar = document.getElementById( 'wpadminbar' );
    const adminBarHeight = adminBar ? adminBar.offsetHeight : 0;
    const headerHeight = document.querySelector( 'header' ).offsetHeight + adminBarHeight;
      
    const section = e.target.closest( 'section' );
    const eventsFilter = section.querySelector( '.e-stn--common-general-post' );
    const sectionTop = section.getBoundingClientRect().top + window.scrollY - 20;
    const scrollTopPos = eventsFilter ? ( eventsFilter.getBoundingClientRect().top + window.scrollY ) : sectionTop;
      
    window.scrollTo( {
      top: scrollTopPos - headerHeight,
      behavior: 'smooth'
    } );
  }
} );