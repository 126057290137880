/* eslint-disable no-unused-vars, no-undef */
import { Fancybox } from '@fancyapps/ui';

document.addEventListener( 'DOMContentLoaded', () => {
  const commonLeadershipPost = document.querySelector( '.e-stn--common-our-leaders' );
  if ( !commonLeadershipPost ) return;

  const clickCards = document.querySelectorAll( '.e-sgp--list-item a[data-fancybox]' );
  const pathName = window.location.pathname;
  const urlParams = new URLSearchParams( window.location.search );
  const urlLeaderId = urlParams.get( 'id' );
  let loadedPostIds = [];

  function initializeFancybox() {
    Fancybox.bind( '.e-sgp--list-item a[data-fancybox]', {
      on: {
        initLayout: fancybox => {
          document.body.classList.add( 'body-scroll-disable' );
          fancybox.$container.classList.add( 'leadership-popup' );
        },
        ready: fancybox => fancybox.$container.classList.add( 'slide-in' ),
        closing: () => {
          document.body.classList.remove( 'body-scroll-disable' );
          window.history.pushState( null, '', pathName );
        }
      }
    } );
  }

  function updateURL( permalink = pathName ) {
    window.history.pushState( null, '', permalink );
  }

  function handleCardClick( event ) {
    const permalink = event.currentTarget.querySelector( '.e-sgp__inner' ).dataset.permalink;
    updateURL( permalink );
  }

  async function fetchLeaderContent( leaderId ) {
    const formData = new FormData();
    formData.append( 'action', 'get_leadership_post_content' );
    formData.append( 'security', leadershipAjax.security );
    formData.append( 'post_id', leaderId );

    const response = await fetch( leadershipAjax.ajax_url, { method: 'POST', body: formData } );
    const data = await response.json();
    if ( !data.success ) throw new Error( data.data.message );

    const container = document.querySelector( '.facetwp-template .b3rg-posts .wp-block-b3rg-row.e-row' );
    if ( !container ) throw new Error( 'Target container not found.' );

    container.insertAdjacentHTML( 'beforeend', data.data.content );
    initializeFancybox();
    updateURL( data.data.permalink );
    document.querySelector( `[data-src="#b3rg-post__popup--${data.data.post_id}"]` )?.click();
  }

  async function fetchCustomPermalinks( postIds ) {
    const formData = new FormData();
    formData.append( 'action', 'get_leadership_custom_permalink' );
    formData.append( 'security', leadershipAjax.security );
    formData.append( 'post_ids', JSON.stringify( postIds ) );

    const response = await fetch( leadershipAjax.ajax_url, { method: 'POST', body: formData } );
    const data = await response.json();
    if ( !data.success ) throw new Error( data.data.message );

    Object.entries( data.data ).forEach( ( [key, value] ) => {
      document.querySelector( `a.b3rg-post__trigger[data-src="#b3rg-post__popup--${key}"] .e-sgp__inner` )
        ?.setAttribute( 'data-permalink', value );
    } );

    clickCards.forEach( item => ( item.style.pointerEvents = 'auto' ) );
  }

  clickCards.forEach( item => {
    item.style.pointerEvents = 'none';
    item.addEventListener( 'click', handleCardClick );
  } );
  initializeFancybox();

  loadedPostIds = Array.from( clickCards )
    .map( anchor => {
      const dataSrc = anchor.getAttribute( 'data-src' ) || '';
      const numberStr = dataSrc.replace( '#b3rg-post__popup--', '' );
      return parseInt( numberStr, 10 );
    } )
    .filter( Boolean );

  if ( urlLeaderId ) {
    const clickElement = document.querySelector( `a.b3rg-post__trigger[data-src="#b3rg-post__popup--${urlLeaderId}"]` );
    clickElement ? setTimeout( () => clickElement.click(), 500 ) : fetchLeaderContent( urlLeaderId );
  }

  fetchCustomPermalinks( loadedPostIds );
} );
