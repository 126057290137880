const backgroundAnimateElement = document.querySelectorAll( '.e-stn--on-load-image-animation' );

function addShowClass( element ) { 
  if ( element ) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const callback = ( entries ) => {
      entries.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          element.classList.add( 'show-svg' );
        }
      } );
    };

    const observer = new IntersectionObserver( callback, options );
    observer.observe( element );
  }
}


backgroundAnimateElement.forEach( ( element ) => addShowClass( element ) );
