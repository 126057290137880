const handleMouseOut = ( e ) => {
  const element = e.target;
  const cardsRow = document.querySelectorAll( '.e-sld--how-we-help-slider .wrapped-elements' );
  const cards = document.querySelectorAll( '.e-sld--how-we-help-slider .swiper-slide' );

  // Remove and add classes using classList
  element.classList.remove( 'card-wrapper-hovered' );
  element.classList.add( 'card-wrapper-hover-removed' );
  
  const eCrds = element.querySelectorAll( '.e-crd' );
  eCrds.forEach( ( card ) => {
    card.classList.add( 'card-hover-removed' );
    card.classList.remove( 'card-hovered' );
  } );

  // Remove class from parent and siblings
  const parent = element.parentElement;
  if ( parent ) {
    parent.classList.remove( 'cards-row-hovered' );
    const siblings = parent.parentElement ? parent.parentElement.children : [];
    Array.from( siblings ).forEach( ( sibling ) => {
      if ( sibling !== parent ) {
        sibling.classList.remove( 'cards-row-hovered' );
      }
    } );
  }

  // If cardsRow exists, reset transform
  if ( cardsRow ) {
    cardsRow.forEach( ( row ) => {
      row.style.transform = 'none';
    } );
  }

  // Remove 'card-wrapper-hovered' class from each card
  cards.forEach( ( card ) => {
    card.classList.remove( 'card-wrapper-hovered' );
  } );
};

export { handleMouseOut };
