const postListLabel = {
  originalDom: [],
  
  init: function () {
    if ( this.originalDom.length === 0 ) {
      this.originalDom = Array.from( document.querySelectorAll( '.e-div--post-list-label' ) );
    }
  
    if ( this.originalDom.length === 0 ) return;
  
    this.originalDom.forEach( ( originalTitleCard ) => {
      const titleCard = originalTitleCard.cloneNode( true );
      const section = originalTitleCard.closest( 'section' );
      const postWrapper = section.querySelector( '.facetwp-template .b3rg-posts .row' )
          || section.querySelector( '.facetwp-template-static .b3rg-posts .row' );
      const firstElement = postWrapper?.querySelector( ':scope > .e-col' );
  
      if ( !firstElement || postWrapper.querySelector( '.e-div--post-list-label' ) ) return;
  
      const titleCardWrapper = document.createElement( 'div' );
      titleCardWrapper.className = firstElement.className;
      titleCard.classList.add( 'active' );
      titleCardWrapper.appendChild( titleCard );
      postWrapper.prepend( titleCardWrapper );
    } );
  }
};
document.addEventListener( 'facetwp-loaded', () => postListLabel.init() );