document.addEventListener( 'DOMContentLoaded', () => { 
  const carousels = document.querySelectorAll( '.e-stn--card-auto-scroll .e-div--marquee-left' );
  if ( carousels.length === 0 ) return;

  // Clone tracks
  carousels.forEach( ( carousel ) => {
    const initialTrack = carousel.querySelectorAll( ':scope > .e-div' );
    if ( !initialTrack ) return;
    // Ensure no duplicates
    const numClones = 5;
    if ( !carousel.dataset.cloned ) {
      Array.from( { length: numClones } ).forEach( () => {
        initialTrack.forEach( ( track ) => {
          track.classList.add( 'marquee-wrapper' );
          const clone = track.cloneNode( true );
          clone.dataset.isClone = 'true'; // Mark the clone
          carousel.appendChild( clone );
        } );
      } );
      carousel.dataset.cloned = 'true'; // Mark the carousel as processed
    }
  } );

  // Pause scrolling
  const cardCrousels = document.querySelectorAll( '.e-stn--card-auto-scroll .e-lnk' ) ;
  if ( cardCrousels.length === 0 ) return;
  cardCrousels.forEach( ( cardCrousel ) => {
    cardCrousel.addEventListener( 'mouseover', ( event ) => {
      event.target.closest( '.e-stn--card-auto-scroll' ).classList.add( 'pause-slider' );
    } );

    cardCrousel.addEventListener( 'mouseout', ( event ) => {
      event.target.closest( '.e-stn--card-auto-scroll' ).classList.remove( 'pause-slider' );
    } );
  } );
} );

//  detect when a page is hidden and shown again - ipad and iphone
document.addEventListener( 'visibilitychange', function () {
  if ( document.visibilityState === 'visible' ) {
    const carouselsSection = document.querySelectorAll( '.e-stn--card-auto-scroll' );
    if ( carouselsSection.length ) {
      carouselsSection.forEach( function ( section ) {
        section.classList.remove( 'pause-slider' );
      } );
    }
  }
} );
