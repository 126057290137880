const mediaQueryOne = window.matchMedia( '(min-width: 992px)' );

const setupCardRowHandling = ( cardsRows ) => {
  if ( mediaQueryOne.matches ) {
    cardsRows.forEach( ( cardsRow ) => {
      cardsRow.addEventListener( 'mouseleave', ( e ) => {
        setTimeout( () => {
          const target = e.target;
          
          // Find the swiper-slide and remove class 'card-wrapper-hovered'
          const swiperSlides = target.querySelectorAll( '.swiper-slide' );
          swiperSlides.forEach( slide => slide.classList.remove( 'card-wrapper-hovered' ) );
          
          // Find the e-crd and remove class 'card-hovered'
          const eCrds = target.querySelectorAll( '.e-crd' );
          eCrds.forEach( card => card.classList.remove( 'card-hovered' ) );
          
          // Find the closest '.cards-container' and then find '.wrapped-elements' and reset the transform style
          const cardsContainer = target.closest( '.cards-container' );
          if ( cardsContainer ) {
            const wrappedElements = cardsContainer.querySelectorAll( '.wrapped-elements' );
            wrappedElements.forEach( wrapper => {
              wrapper.style.transform = 'none';
            } );
          }
        }, 570 );
      } );
    } );
  }
};

export { setupCardRowHandling };
