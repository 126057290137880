document.addEventListener( 'DOMContentLoaded', () => {
  const parallaxContainer = document.querySelector( '.e-stn--section-one' );
  
  
  if ( parallaxContainer ) {

    const addClassNew = ( ) => {

      if ( !parallaxContainer.classList.contains( 'e-stn--sticky-banner' ) ) {
        return;
      }
      const heightWindow = window.innerHeight;
      const widthWindow = window.innerWidth;
      const sectionHeight = parallaxContainer.offsetHeight;
      const heading = parallaxContainer.querySelector( 'h1' );
      const content = parallaxContainer.querySelector( 'h1 + p' );
      const counterArea = parallaxContainer.querySelector( '.e-div--content-wrapper' );
      const scrollValue = window.scrollY;

      if ( heightWindow > sectionHeight && widthWindow >= 1200 ) {
        Object.assign( parallaxContainer.style, {
          position: 'sticky',
          top: '0',
          display: 'flex',
          alignItems: 'center',
        } );
      } else {
        Object.assign( parallaxContainer.style, {
          position: 'sticky',
          top: `${heightWindow - sectionHeight - 100}px`,
          display: 'block',
        } );
      }
      
      heading.style.transform = `translate3d(0, -${scrollValue / 15}px, 0)`;
      if( content ) {
        content.style.transform = `translate3d(0, -${scrollValue / 10}px, 0)`;
      }
      counterArea.style.transform = `translate3d(0, -${scrollValue / 20}px, 0)`;
    };

    const onScrollOrResize = () => addClassNew();
    window.addEventListener( 'scroll', onScrollOrResize );
    window.addEventListener( 'load', onScrollOrResize );
    window.addEventListener( 'resize', onScrollOrResize );
  }
} );
