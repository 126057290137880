const visionMissionLoadingSection = document.querySelector( '.e-stn--careers-our-vision-mission' );

if ( visionMissionLoadingSection ) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  };

  const callback = entries => {
    entries.forEach( entry => {
      if ( entry.isIntersecting ) {
        visionMissionLoadingSection.classList.add( 'animate-section' );
      }
    } );
  };

  const observer = new IntersectionObserver( callback, options );

  observer.observe( visionMissionLoadingSection );
}
