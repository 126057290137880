import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

document.addEventListener( 'DOMContentLoaded', function() {
  const h1Element = document.querySelector( '.animated-heading' );
  
  if ( h1Element ) {
    // Wrap each word in a span for animation
    const textContent = h1Element.textContent;
    const words = textContent.split( ' ' );
    const wrappedText = words.map( word => `<span class="fade-type-1">${word}</span>` ).join( ' ' );
    h1Element.innerHTML = wrappedText;
  
    // Set initial state and animate each word's opacity
    gsap.set( '.fade-type-1', { visibility: 'visible', opacity: 0.2 } );
    gsap.to( '.fade-type-1', { opacity: 1, duration: 0.5, stagger: 0.2 } );
  }
} );
  