/* eslint-disable max-len */
document.addEventListener( 'DOMContentLoaded', () => {
  const homeBanner = document.querySelector( '.e-stn--main-banner' );

  const options = {
    root: null,
    rootMargin: '0px 0px -100% 0px',
    threshold: 0
  };

  const intersectionCallback = ( entries ) => {
    entries.forEach( entry => {
      homeBanner.style.opacity = entry.isIntersecting ? '0' : '1';
    } );
  };

  const observer = new IntersectionObserver( intersectionCallback, options );
  const sections = document.querySelectorAll( '.e-stn--home-challenging-animation ~ section' );
  
  
  sections.forEach( section => {
    observer.observe( section );
  } );
} );
