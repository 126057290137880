document.addEventListener( 'DOMContentLoaded', function() {
  const cards = document.querySelectorAll( '.e-div--careers-why-join-card' );
  
  cards.forEach( function( card ) {
    const title = card.querySelector( 'h3' );
    if ( title ) {
      const titleClone = title.cloneNode( true ); // Clone the <h3> element
  
      const innerDiv = card.closest( '.e-div' ).querySelector( '.e-div--careers-why-join-card-inner > div' );
        
      // Check if the <h3> has already been cloned to avoid duplicates
      if ( !innerDiv.querySelector( 'h3' ) ) {
        innerDiv.prepend( titleClone ); // Prepend the cloned <h3> to the div
      }
    }
  } );
} );
  