document.addEventListener( 'DOMContentLoaded', function() {
  const prImages = document.querySelectorAll( '.e-div--life-at-parallax-images' ),
    parallaxSection = document.querySelector( '.e-stn--careers-life-at' ),
    speedValues = -0.1;
  
  if ( !parallaxSection || parallaxSection.length === 0 ) {
    return false;
  }
  
  const observer = new IntersectionObserver( entries => {
    entries.forEach( entry => {
      if ( entry.isIntersecting ) {
        window.addEventListener( 'scroll', parallaxScroll );
      } else {
        window.removeEventListener( 'scroll', parallaxScroll );
      }
    } );
  } );
  
  observer.observe( parallaxSection );
  
  function parallaxScroll() {
    const scrollY = parallaxSection.getBoundingClientRect().top + 100;
  
    prImages.forEach( image => {
      const yOffset = scrollY * speedValues;
      image.style.bottom = `${yOffset}px`;
    } );
  }
  parallaxScroll();
} );
  