/* eslint-disable no-unused-vars, max-len */
const cardsSection = document.querySelector( '.e-stn--home-how-we-help-clients' );
const mediaQueryTwo = window.matchMedia( '(min-width: 1400px)' );
const mediaQueryThree = window.matchMedia( '(min-width: 992px)' );
let hiddenWidth;

const hiddenWidthCalculate = ( cardsWrapperWidth, windowWidth ) => {
  if ( cardsWrapperWidth > windowWidth ) {
    hiddenWidth = ( cardsWrapperWidth - windowWidth ) / 2 + 15;
  } else {
    hiddenWidth = 0;
  }
  return hiddenWidth;
};

const cardHDBehavior = ( element ) => {
  let transformValue = 0;
  let siblingTransformValue = 0;
  
  if ( element.classList.contains( 'card--right-side-first' ) || element.classList.contains( 'card--right-side-second' ) ) {
    element.closest( '.wrapped-elements' ).classList.add( 'float-right' );
  }
  
  return [transformValue, siblingTransformValue];
};

// Function to reset hover effects on the cards
const resetHoverEffects = ( element ) => {
  const wrappedElements = element.closest( '.wrapped-elements' ).querySelectorAll( '.swiper-slide' );
  wrappedElements.forEach( ( slide ) => {
    slide.classList.remove( 'card-wrapper-hovered' );
    slide.querySelector( '.e-crd' ).classList.remove( 'card-hovered' );
  } );

  const parentElement = element.parentElement;
  parentElement.classList.remove( 'float-right' );
  element.classList.remove( 'card-wrapper-hover-removed' );
  element.querySelector( '.e-crd' ).classList.remove( 'card-hover-removed' );
};

// Function to apply hover effects on the target card and its siblings
const applyHoverEffects = ( element ) => {
  const parentElement = element.parentElement;

  element.classList.add( 'card-wrapper-hovered' );
  element.querySelector( '.e-crd' ).classList.add( 'card-hovered' );
  parentElement.classList.add( 'cards-row-hovered' );
  
  Array.from( parentElement.parentElement.children ).forEach( ( sibling ) => {
    if ( sibling !== parentElement ) {
      sibling.classList.add( 'cards-row-hovered' );
    }
  } );
};

// Function to calculate expanding width based on media query
const calculateExpandingWidth = ( cardsWrapperWidth, windowWidth ) => {
  hiddenWidthCalculate( cardsWrapperWidth, windowWidth );

  if ( mediaQueryTwo.matches ) {
    return 260 + hiddenWidth + 20;
  } else if ( mediaQueryThree.matches ) {
    return 280 + hiddenWidth + 20;
  }
  return 300;
};

// Function to calculate transform values for the element
const calculateTransformValues = ( element, expandingWidth ) => {
  let transformValue;
  let siblingTransformValue;

  if ( element.classList.contains( 'card--right-side-first' ) ) {
    transformValue = -expandingWidth + 'px';
    siblingTransformValue = -hiddenWidth + 'px';
  } else if ( element.classList.contains( 'card--right-side-second' ) ) {
    transformValue = -( expandingWidth / 2 ) + 'px';
  } else if ( element.classList.contains( 'card--center-side' ) ) {
    transformValue = expandingWidth / 4 + 'px';
  } else {
    transformValue = hiddenWidth + 'px';
    siblingTransformValue = hiddenWidth + 'px';
  }

  return { transformValue, siblingTransformValue };
};

// Function to apply transforms to the wrapped elements
const applyTransforms = ( element, { transformValue, siblingTransformValue } ) => {
  const wrappedElementsClosest = element.closest( '.wrapped-elements' );
  wrappedElementsClosest.style.transform = `translateX(${transformValue})`;

  Array.from( wrappedElementsClosest.parentElement.children ).forEach( ( sibling ) => {
    if ( sibling !== wrappedElementsClosest ) {
      sibling.style.transform = `translateX(${siblingTransformValue})`;
    }
  } );
};

// Function to reset transforms on wrapped elements and siblings
const resetTransforms = ( element ) => {
  const wrappedElementsClosest = element.closest( '.wrapped-elements' );
  wrappedElementsClosest.style.transform = 'none';

  Array.from( wrappedElementsClosest.parentElement.children ).forEach( ( sibling ) => {
    if ( sibling !== wrappedElementsClosest ) {
      sibling.style.transform = 'none';
    }
  } );
};

// Main mouseover handler
const handleMouseOver = ( e, cardsWrapperWidth, windowWidth ) => {
  const element = e.target;

  // Reset hover effects
  resetHoverEffects( element );

  // Apply hover effects
  applyHoverEffects( element );

  let expandingWidth = calculateExpandingWidth( cardsWrapperWidth, windowWidth );
  let { transformValue, siblingTransformValue } = calculateTransformValues( element, expandingWidth );

  // Apply transforms or reset them based on width condition
  if ( cardsWrapperWidth > windowWidth ) {
    applyTransforms( element, { transformValue, siblingTransformValue } );
  } else {
    [transformValue] = cardHDBehavior( element ); //NOSONAR
    resetTransforms( element );
  }
};

export { handleMouseOver };
