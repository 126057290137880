// Select the section and video elements
const hasSectionSelect = document.querySelector( '.e-stn--home-we-are-one-team-accordion' );
const hasVideos = hasSectionSelect?.querySelectorAll( 'video' );

// Add playsinline attribute to each video when the DOM is ready
document.addEventListener( 'readystatechange', event => {
  if ( event.target.readyState === 'interactive' ) {
    hasVideos?.forEach( item => {
      item.setAttribute( 'playsinline', '' );
    } );
  }
} );

document.addEventListener( 'DOMContentLoaded', () => {
  const hasSection = document.querySelector( '.e-stn--home-we-are-one-team-accordion' );
  if ( hasSection === null ) return;

  const accordionItems = hasSection.querySelectorAll( '.e-div--content-wrapper' );
  const videos = hasSection.querySelectorAll( 'video' );
  let isIntersected = false;

  // Add label based on heading (h3) for each accordion item
  accordionItems.forEach( item => {
    const getTitle = item.querySelector( 'h3' ).innerText;
    const createLabel = document.createElement( 'label' );
    createLabel.textContent = getTitle;
    item.prepend( createLabel );
  } );

  // Set the first accordion item as active
  accordionItems[0].classList.add( 'active' );

  // Function to play a video at the given index
  const playVideo = index => {
    videos[index].play();
    isIntersected = true; // Update the isIntersected variable when the video is played
  };

  // Function to pause a video at the given index
  const pauseVideo = index => {
    videos[index].pause();
  };

  // Toggle accordion items on click
  accordionItems.forEach( ( item, index ) => {
    item.addEventListener( 'click', el => {
      // Remove active class and pause all videos
      accordionItems.forEach( ( acc, i ) => {
        acc.classList.remove( 'active' );
        pauseVideo( i );
      } );
      // Set clicked item as active and play the video
      el.currentTarget.classList.add( 'active' );
      playVideo( index );
    } );
  } );

  // Observe the section to play the first video when it comes into view
  const io = new IntersectionObserver( entries => {
    if ( entries[0].isIntersecting && !isIntersected ) {
      playVideo( 0 );
    }
  } );
  io.observe( hasSection );
} );
